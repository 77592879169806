body {
  padding-top: 0px;
}

.vertical-center {
  min-height: 100%; /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}

label span{
  display:inline-block;
  margin-left: 10px;
}

input[type="radio"]{
  margin: 0 10px 0 10px;
}
