:root {
    --NIFButtonColor: #525f7f;
    --NIFButtonHoverColor: #3c4858;
}

body, html {
    margin: 0;
    padding: 0;
}

.archive-body {
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
}

.new-identifier-search-archive-body {
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
}

.new-identifier-search-container {
    margin: auto;
    width: 90%;
}

.new-identifier-search-header {
    background-color: #f5f5f5;
    margin: auto;
    width: auto;
}

.new-identifier-search-dropdown {
    display: flex;
    justify-content: center;
    max-height: 400px;
    max-width: 200px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none; 
}

.new-identifier-search-filter {
    display: flex;
    justify-content: center;
    text-align: center;
}

.new-identifier-search-filter:hover {
    cursor: pointer;
}

.new-identifier-search-filter-item {
    display: flex;
    margin: 0 !important;
    justify-content: center;
    width: 100%;
}

.new-identifier-search-img-preview{
    height: 50px;
    width: auto;
}

.new-identifier-search-table-popup {
    background-color: #f5f5f5;
    border-radius: 5px;
    color: #000;
    width: 300px;
    max-height: 300px;
    overflow-y: auto;
}

.new-identifier-search-img-popup-div {
    background-color: #11cdef;
    border-radius: 5px;
    color: #000;
    max-width: 300px;
    height: auto;
    padding: 5px;
    overflow-y: auto;
}
.new-identifier-search-img-popup-div > div {
    background-color: #ffffff;
}

.new-identifier-search-table-popup-img {
    max-width: 100%;
}

.new-identifier-search-table-cell {
    max-width: 20px;
    background-color: #f5f5f5;
    text-align: center;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none; 
    vertical-align: baseline !important;
}


.new-identifier-search-table-cell::-webkit-scrollbar{
    display: none;
}

.new-identifier-search-table-cell:hover {
    background-color: #d0cdcd;
    cursor: pointer;
}

.NIFCheckbox:hover {
    cursor: pointer;
}

.NIFCheckboxNoHover {
    cursor: initial;
}

.new-identifier-search-table-cell-no-hover{
    max-width: 20px;
    background-color: #f5f5f5;
    text-align: center;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    vertical-align: baseline !important;
}

.new-identifier-search-table-cell-no-hover::-webkit-scrollbar {
    display: none;
  }

.new-identifier-search-table {
    border: #000 solid 1px;
    max-height: 475px;
    overflow-y: auto;
}

.new-identifier-search-table::-webkit-scrollbar {
    display: none;
  }

.new-identifier-search-table thead th {
    position: sticky;
    top: 0;
    background-color: #f5f5f5 !important;
    z-index: 1; /* Add a higher z-index value to ensure the header appears above the table border */
}

.new-identifier-search-table thead th::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: #000 solid 1px;
  }


.AutoCreateTable {
    max-height: 1000px;
    overflow-y: auto;
}

.PdpArchiveDropdown > button {
    font-size: 0.8125rem;
    font-weight: 400;
    font-family: Open Sans, sans-serif;
    color: #525f7f;
    box-shadow: none;
    background-color: inherit;
    border: none;
}

.PdpArchiveDropdown > button:hover {
    box-shadow: none;
    background-color: inherit;
    border: none;
    margin-left: 0;
}

.PdpArchiveDropdown > button:active {
    box-shadow: none;
    background-color: inherit;
    border: none;
}

.PdpArchiveSearchBox {
    border-radius: 5px 0px, 0px, 5px !important;
    width: 200px !important;
}

.PdpArchiveSearchButton {
    background-color: var(--NIFButtonColor) !important;
    border-radius: 5px !important;
    height: 46px !important;
}

.PdpArchiveSearchButton:hover {
    background-color: var(--NIFButtonHoverColor) !important;
    border-radius: 5px !important;
}

.PdpArchiveSearchButton > img {
    width:25px;
    height: auto;
}

.NIFButton {
    background-color: var(--NIFButtonColor) !important;
    color: aliceblue !important;
    border: var(--NIFButtonColor) solid 1px !important;
}

.NIFButton:hover {
    background-color: var(--NIFButtonHoverColor) !important;
    color: aliceblue !important;
    border: var(--NIFButtonColor) solid 1px !important;
}

.PdpArchiveSettingsButton {
    background-color: white;
    border-radius: 5px;
    width: 50px;
    height: 46px;
}

.PdpArchiveSettingsButton > img {
    width:25px;
    height:auto;
}

.NIFLoadingPopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(200, 200, 200, 0.8);
}

.new-identifier-search-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(200, 200, 200, 0.8);
}

.new-identifier-search-window {
    border-radius: 10px;
    width: 50% !important;
    background-color: #172b4d;
}

.new-identifier-search-window-header {
    background-color: #11cdef;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    justify-content: center;
    padding-top: 1%;
    padding-bottom: 1%;
}

.UploadReportHeaderText {
    color: white;
}

.ClosePopupElement{
    max-height: 100%;
    width: 25px;
}

.ClosePopupElement:hover {
    cursor: pointer;
}

.UploadReportDiv {
    height: 40vh;
    margin: 20px !important;
    border: 4px dashed white;
    padding: 0 !important;
}

.UploadReportDiv:hover {
    cursor: pointer;
}

.UploadReportDiv h1 {
    color: white !important;
}

.UploadReportInput {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-left: 15%;
  cursor: pointer;
  color: #fff;
  border-radius: 4px;
}

.custom-file-upload:hover {
    cursor: pointer;
}

.text-navy {
    color: #172b4d;
}

.bg-navy {
    background-color: #172b4d !important;
}

.text-baby-blue {
    color: #11cdef;
}

.bg-baby-blue {
    background-color: #11cdef !important;
}

.new-identifier-search-ml-dropdown {
    max-height: 300px;
    overflow-y: scroll;
}

.search-term-table {
    max-height: 250px !important;
    overflow-y: scroll;
}

.selected-row {
    background-color: #ddeffd !important;
}

.search-term-row:hover {
    cursor:pointer;
    background-color: #a3aabb;
}

.search-term-header {
    border-radius: 10px 10px 0px 0px;
}

.search-term-header > h5 {
    font-weight: bold !important;
}

.search-term-footer {
    border-radius: 0px 0px 10px 10px;
}

.search-term-footer > h5 {
    font-weight: bold !important;
}

.search-term-footer-button:hover {
    background-color: #a3aabb;
}

.search-term-footer-button-left:hover {
    cursor: pointer;
    border-radius: 0px 0px 0px 10px;
}

.search-term-footer-button-right:hover {
    cursor: pointer;
    border-radius: 0px 0px 10px 0px;
}

.new-identifier-tooltip {
    z-index: 9999 !important;
}